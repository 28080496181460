import { FC } from 'react';

import { NavigationMenu, NavigationMenuWrapper } from '@custom-components/MegaMenu';
import { Placeholder, usePlaceholderComponentsForRendering } from '@sitecore/common';
import { MegaMenuRendering } from '@sitecore/types/manual/MegaMenu';
import { Grid, Stack } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { useI18nTranslation } from '@sparky/providers';

const MegaMenu: FC<MegaMenuRendering> = ({ placeholders }) => {
  const mainNavigation = useI18nTranslation('mainNavigation');
  const isDesktop = useMediaQuery('lg');
  const hasCard = !!placeholders?.['jss-megamenu-cards']?.length;
  const megaMenuDivisions = usePlaceholderComponentsForRendering('jss-megamenu-divisions');
  return isDesktop ? (
    <NavigationMenuWrapper>
      <NavigationMenu.Root aria-label={mainNavigation}>
        <NavigationMenu.List asChild>
          <Stack as="ul" direction="row" gap="6">
            {megaMenuDivisions.map((child: JSX.Element) => {
              return child.type === 'code' ? child : <NavigationMenu.Item key={child.key}>{child}</NavigationMenu.Item>;
            })}
          </Stack>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </NavigationMenuWrapper>
  ) : (
    <Stack gap="7" direction={{ md: hasCard ? 'row' : 'column' }}>
      <Stack.Item as="nav" grow aria-label={mainNavigation}>
        <Grid as="ul" gridTemplateColumns={{ md: hasCard ? '1fr' : '1fr 1fr' }} gap="5">
          {megaMenuDivisions.map((child: JSX.Element) =>
            child.type === 'code' ? child : <li key={child.key}>{child}</li>,
          )}
        </Grid>
      </Stack.Item>
      {hasCard ? (
        <Stack.Item grow>
          <Placeholder name="jss-megamenu-cards" />
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default MegaMenu;
